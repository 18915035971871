<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="serviceIcon" alt=""> 
				        	VIP {{ serviceName }} {{ formService.provider | ucfirst }} 
				        	<router-link :to="listURL" class="btn bg-main btn-sm"><i class="fa fa-list"></i> Danh sách ID</router-link>
				        </h3>

				        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.service_vip"></Notification>

				        <form action="#" @submit.prevent="buyService" v-if="hasService">
				        	<div class="form-group">
				        		<label>ID hoặc Link :</label>
				        		<input type="text" class="form-control" placeholder="Nhập ID hoặc Link Facebook cần cài VIP" v-model="formService.id">
				        	</div>
				        	<div class="form-group">
				        		<label>Ghi chú :</label>
				        		<textarea class="form-control" v-model="formService.note" rows="2" placeholder="Nhập ghi chú hoặc bỏ trống"></textarea>
				        	</div>

				        	<div class="form-group">
				        		<label>Chọn Server <i class="fa fa-sort" @click="sortType"></i> :</label>
				        		<div class="form-check" v-if="serviceType" v-for="(sv, key) in serviceType">
								    <label class="form-check-label" :for="sv.type">
								    	<input class="form-check-input" type="radio" name="type" v-model="formService.type" :value="sv.type" :id="sv.type" :checked="(key == 0 ? true : false)" @change="checkType()" style="margin-right:3px;">
								       	<span class="label b-danger">SV{{ key + 1 }}</span> <span class="label b-info">{{ sv.price | numberFormat }}đ</span> {{ sv.name }}
								    </label>
								</div>
				        	</div>

				        	<div class="form-group" v-if="formService.type.includes('eye_live_stream')">
				        		<label>Tổng số lần Live trong thời hạn mua ( 1 ~ 100 ) :</label>
				        		<input type="text" class="form-control" placeholder="Tổng số lần Live trong thời hạn mua" v-model="formService.max_posts">
				        	</div>

				        	<div class="form-group" v-if="context.lists_minute_view">
				        		<label>Số phút xem Live Stream :</label>
				        		<select class="form-control" v-model="formService.minute_view">
				        			<option :value="min" v-for="min in context.lists_minute_view">{{ min }} phút</option>
				        		</select>
				        	</div>

				        	<div class="form-group" v-if="context.lists_speed">
				        		<label>Tốc độ ( Phút ) :</label>
				        		<select class="form-control" v-model="formService.speed">
				        			<option :value="sp" v-for="sp in context.lists_speed">{{ sp }} {{ serviceName }} / Phút</option>
				        		</select>
				        	</div>

				        	<div class="form-group" v-if="hasComment">
				        		<label>Nội dung bình luận : <span class="label b-info">{{ commentCount }}</span> :</label>
				        		<textarea v-model="formService.comments" class="form-control" placeholder="Nhập nội dung bình luận, mỗi nội dung khác nhau thì xuống hàng" :rows="commentRows" @input="countComment" required=""></textarea>
				        		<div class="alert b-danger" style="margin-top:7px;">Chú ý : Chạy ngẫu nhiên từng bình luận nên nhập nhiều nội dung bình luận để không bị trùng lặp!</div>
				        	</div>

				        	<div class="form-group" v-if="context.lists_posts">
				        		<label>Số bài viết đăng trong 1 ngày :</label>
				        		<select class="form-control" v-model="formService.max_posts">
				        			<option :value="post" v-for="(price, post) in context.lists_posts">{{ post }} bài ( Giá x{{ price }} )</option>
				        		</select>
				        	</div>

				        	<div class="form-group">
				        		<label>Số lượng cần tăng ( {{ serviceData.min | numberFormat }} ~ {{ serviceData.max | numberFormat }} ) :</label>
				        		<vue-numeric class="form-control" v-bind:minus="false" separator="," v-model="formService.quantity" placeholder="Nhập số lượng cần tăng"></vue-numeric>
				        	</div>

				        	<div class="form-group">
				        		<label>Thời hạn :</label>
				        		<select class="form-control" v-model="formService.expired_time">
				        			<option :value="day" v-for="day in context.lists_days">{{ day }} ngày</option>
				        		</select>
				        	</div>

				        	<div class="alert b-warning">Giá dịch vụ của bạn thuộc loại {{ user.roleText }} ( Giảm {{ user.discount }}% ) <router-link to="/price" class="au">Xem bảng giá dịch vụ và nâng cấp!</router-link></div>

				        	<div v-if="formService.type.includes('eye_live_stream')">
				        		<div class="alert b-danger">
					        		- Chú ý : Nếu chọn Tổng số lần Live trong thời hạn mua là 10 và thời hạn mua là 7 ngày thì trong khoảng thời gian 7 ngày đó bạn chỉ được phép Live tối đa là 10 Live ( Live quá 10 Live sẽ không tăng lên được nữa )
					        	</div>
				        		<div class="alert b-info">Tính tiền : (Tổng số lần Live trong thời hạn mua) x (Số phút xem) x (Số lượng cần tăng) x (Giá dịch vụ {{ servicePrice }}đ)</div>
				        	</div>

				        	<div v-else>
				        		<div class="alert b-info">Tính tiền : (Số bài viết đăng trong 1 ngày) x (Số lượng cần tăng) x (Thời hạn) x (Giá dịch vụ {{ servicePrice | numberFormat }}đ)</div>
				        	</div>

				        	<div class="form-group">
				        		<div class="box-price">
				        			<div class="text">Tổng thanh toán</div>
				        			<div class="total-money" ref="serviceMoney">{{ serviceTotalMoney | numberFormat }} VND</div>
				        			<div class="" ref="serviceText">Bạn sẽ cài <span class="note-service">{{ formService.quantity | numberFormat }} VIP {{ serviceName }}</span> thời hạn <span class="note-service">{{ formService.expired_time }} ngày</span> với giá <span class="note-service">{{ servicePrice | numberFormat }}đ / {{ serviceName }}</span></div>
				        		</div>
				        	</div>


				        	<button class="btn b-info btn-lg btn-block">Thanh toán dịch vụ</button>
				        </form>

				        <div class="text-center alert b-danger text-white" v-else>
				        	DỊCH VỤ KHÔNG KHẢ DỤNG HOẶC BẢO TRÌ - VUI LÒNG LIÊN HỆ ADMIN ĐỂ ĐƯỢC HỖ TRỢ !
				        </div>

				        <!-- <div class="text-center" v-else>
				        	<div class="spinner-border m-5" role="status" style="width: 5rem;height: 5rem;">
                            	<span class="visually-hidden">Loading...</span>
                          	</div>
				        </div> -->


				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>

	import '@/assets/css/service.css';

	export default {
		data () {
			return {
				formService: {
					quantity: 0,
					expired_time: 0,
					speed: 0,
					minute_view: 0,
					max_posts: 10
				},
				hasService: true,
				commentRows: 6,
				commentCount: 1,
				totalPrice: 0,
				serviceName: '',
				serviceIcon: '',
				servicePrice: 0,
				serviceMin: 0,
				hasComment: false,
				hasReaction: false,
				isBuyAll: false,
				serviceType: [],
				service: this.services,
				serviceData: {
					min: 0,
					max: 0,
					price: 0
				},
				noteText: '',
				listDays: [],
				listSpeed: [],
				listMinuteViews: [],
				listMaxPosts: [],
				context: []
			}
		},
		props: ['user', 'services', 'settings', 'notification'],
		created () {

			let servicePath = this.$route.params.service;
			servicePath = servicePath.split('_');

			this.formService.type 		= servicePath.slice(0, (servicePath.length - 1)).join('_');
			this.formService.provider 	= servicePath[(servicePath.length - 1)];

			this.listURL = 'services/vip_' + (this.formService.type + '_' + this.formService.provider);

			if (Object.keys(this.service).length === 0) {
				this.$store.dispatch('siteData').then((res) => {
					this.service = res.services;
					this.serviceName = this.service[this.formService.provider + '_vip']['items'][this.formService.type]['name'];
					this.getType();
				});
			} else {
				this.serviceName = this.service[this.formService.provider + '_vip']['items'][this.formService.type]['name'];
				this.getType();
			}

			this.serviceIcon = require('@/assets/images/service/' + this.formService.provider + '_vip/' + this.formService.type + '.png');

			if (this.formService.type == 'cmt' || this.formService.type == 'review_fanpage') {
				this.hasComment = true;
			}

		},
		computed: {
			serviceTotalMoney () {

				let total_money = (this.formService.quantity * this.servicePrice);

				if (this.formService.type.includes('eye_live_stream')) {
					total_money = (total_money * this.formService.minute_view * this.formService.max_posts);
				} else {
					total_money = (total_money * this.formService.expired_time / 30);
					if (this.context.lists_posts && this.context.lists_posts[this.formService.max_posts] > 0) {
						total_money = (total_money * this.context.lists_posts[this.formService.max_posts]);
					}
				}

				return total_money;
			}
		},
		methods: {
			countComment () {
				this.commentCount = this.formService.comments.split("\n").filter(i => i).length;
				if (this.commentCount < 1) {
					this.commentCount = 1;
				}
				if ((this.commentCount + 1) >= 6) {
					this.commentRows = (this.commentCount + 1);
				} else {
					this.commentRows = 6;
				}
			},
			checkType () {

				this.serviceData = this.serviceType.filter(x => x.type == this.formService.type)[0];

				this.filterPercent = this.serviceData.filter_percent;

				if (this.serviceData.context) {

					try {
						this.serviceData.context = JSON.parse(this.serviceData.context);
					} catch {}

					this.context = this.serviceData.context;

					this.formService.expired_time = this.context.lists_days[0];

					if (this.context.lists_minute_view) {
						this.formService.minute_view = this.context.lists_minute_view[0];
					}

					if (this.context.lists_posts) {
						this.formService.max_posts = Object.keys(this.context.lists_posts)[0];
					}

				}

				this.formService.gender = 'all';

				this.formService.quantity = this.serviceData.min;

				this.servicePrice = this.serviceData.price;

			},
			getType () {
				this.$http.post('service/vip/type', this.formService).then(res => {
					if (res.body.length === 0) {
						this.hasService = false;
					}
					this.serviceType = res.body;
					this.formService.type = this.serviceType[0].type;
					this.checkType();
				});
			},
			sortType () {
				if (this.serviceSort == 'ASC') {
					this.serviceSort = 'DESC';
					this.serviceType.sort((a, b) => a.price - b.price);
				} else {
					this.serviceSort = 'ASC';
					this.serviceType.sort((a, b) => b.price - a.price);
				}
			},
			buyService () {
				if (!this.formService.type.includes('eye_live_stream')) {
					delete this.formService.minute_view;
				}
				if (!this.formService.id) {
					return this.$swal(
						'Thông báo',
						'Vui lòng nhập ID hoặc Link cần thực hiện cài VIP ' + this.serviceName,
						'error'
					);
				}
				if (this.formService.quantity < this.serviceData.min) {
					if (!this.formService.comments) {
						this.formService.quantity = this.serviceData.min;
					}
					return this.$swal(
						'Thông báo',
						'Số lượng mua ít nhất là ' + this.$options.filters.numberFormat(this.serviceData.min) + ' ' + this.serviceName,
						'error'
					);
				}
				if (this.formService.quantity > this.serviceData.max) {
					if (!this.formService.comments) {
						this.formService.quantity = this.serviceData.max;
					}
					return this.$swal(
						'Thông báo',
						'Số lượng mua tối đa là ' + this.$options.filters.numberFormat(this.serviceData.max) + ' ' + this.serviceName, 
						'error'
					);
				}
				this.$swal({
					title: 'Xác nhận ?',
					html: this.$refs.serviceText.innerHTML + '<br> Tổng thanh toán : <b>' + this.$refs.serviceMoney.innerHTML + '</b>',
					imageUrl: this.serviceIcon,
					imageHeight: '100px',
					showCancelButton: true,
					confirmButtonText: 'Thanh toán'
				}).then((isConfirm) => {
					if (isConfirm.value) {
						this.$http.post('service/vip/buy', this.formService).then(res => {
							res.body.message = (res.body.message_html ? res.body.message_html : res.body.message)
							if (res.body.type == 'no_money') {
								this.$swal({
									icon: 'error',
									html: res.body.message,
									showCancelButton: 1,
									confirmButtonText: 'Nạp tiền'
								}).then((isConfirm2) => {
									if (isConfirm2.value) {
										this.$router.push('/payment');
									}
								});
							} else {
								if (res.body.status == 1) {
									this.$swal({
										icon: 'success',
										text: res.body.message,
										cancelButtonText: 'Danh sách',
										confirmButtonText: 'Mua tiếp',
										showCancelButton: 1
									}).then((isConfirm) => {
										if (!isConfirm.value) {
											this.$router.push('/' + this.listURL);
										}
									});
								} else {
									this.$swal('Thông báo', res.body.message, 'error');
								}
							}
						});
					}
				});
				// console.log(this.formService)
			},
			/*getType () {
				this.$http.post('service/vip/type', this.formService).then(res => {
					if (!res.body.price) {
						this.hasService = false;
					}
					this.serviceData = res.body;
					this.formService.quantity = this.serviceData.min;
					this.servicePrice = this.serviceData.price;
					this.listDays = this.serviceData.listDays;
					this.listSpeed = this.serviceData.listSpeed;
				});
			}*/
		}
	}
</script>